<template>
  <div class="error">
    <div class="form">
      <v-logo class="title" @click.native="toHome" />
      <div class="message">Страница не найдена</div>
      <v-button @click="toHome" title="На главную" />
    </div>
  </div>
</template>

<script>
import button from "@/components/common/button";
import logo from "../components/common/logo";
export default {
  name: 'Error',
  components: {
    'v-button': button,
    'v-logo': logo
  },
  methods: {
    toHome() {
      localStorage.getItem('access') ?
        this.$router.push({name: 'Home'}) :
        this.$router.push({name: 'Login'})
    }
  }
}
</script>

<style lang="sass">
.error
  display: grid
  min-height: 100vh
  .form
    margin: auto
    display: grid
    grid-template-rows: repeat(3, min-content)
    grid-gap: 10px
    .title
      width: 100px
      height: auto
      margin: 0 auto
      cursor: pointer
</style>